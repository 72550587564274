
import VueRecaptcha from "vue-recaptcha";
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

interface ticketData {
  street: string;
  street_number: string;
  hint: string;
  message: string;
}

interface respuesta {
  id_pregunta: number | null;
  id_formulario: number | null;
  pregunta: string | null;
  respuesta: string | null;
  tipo: number | null;
  obligatorio: number | null;
}

interface dataRespuesta {
  id_formulario: number | null;
  preguntas: Array<respuesta>;
}

@Component({
  components: {
    FilePond,
    VueRecaptcha
  }
})
export default class SendTicketForm extends Mixins(FormValidator) {
  protected formData: ticketData = {
    street: "",
    street_number: "",
    hint: "",
    message: ""
  };

  options_street: any = [];
  street_loading = false;
  street_search_empty = false;
  disable_button = false;

  private formulario: any = null;
  private respuestas: Array<dataRespuesta> = [];
  private data_preguntas: any = [];
  id_formulario = 0;
  mensaje = "";

  requiere_documento = false;
  mensaje_documento = "";

  protected captcha_key = "";
  private beforeMount() {
    this.captcha_key = process.env.VUE_APP_KEY_CAPTCHA as string;
  }

  private onVerify(response: any) {
    this.sendTicketAttemp();
  }

  private onExpired() {
    this.$q.notify({
      color: "negative",
      message: "Por favor, recargue la página.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private onError() {
    this.$q.notify({
      color: "negative",
      message: "Ha ocurrido un error, intente más tarde.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private areAllFilesOK(): boolean {
    let files = (this.$refs.pond as any).getFiles();
    for (let i = 0; i < files.length; i++) {
      if (files[i].status != 5) {
        return false;
      }
    }
    return true;
  }

  private triggerFilepond() {
    (document.getElementsByClassName(
      "filepond--browser"
    )[0] as HTMLInputElement).click();
  }

  private typeDetection(file: any, type: any) {
    let checkExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    let isAllowed = new Promise((resolve, reject) => {
      let extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (checkExtensions.indexOf(extension) > -1) {
        let officeType = "";
        switch (extension) {
          case "doc":
          case "docx":
            officeType = "application/doc";
            break;
          case "xls":
          case "xlsx":
            officeType = "application/xls";
            break;
          case "ppt":
          case "pptx":
            officeType = "application/ppt";
            break;
          default:
            officeType = "";
            break;
        }
        if (officeType === "") {
          reject();
        } else {
          resolve(officeType);
        }
      } else {
        resolve(type);
      }
    });
    return isAllowed;
  }

  private mounted() {
    this.getFormulario();
    this.getServicioMeta();
    this.getDocumentMessage();
    /*
    (window as any).grecaptcha.render("grecaptcha", {
      sitekey: "6LfHOFgdAAAAAAoYhdbAQFg6-7c0wyBC-06r0wq-"
    });
    */
  }

  private getDocumentMessage() {
    this.mensaje_documento = "";
    this.requiere_documento = false;
    this.$axios
      .post("servicios/requiere/documento", {
        id_servicio: Number(this.$store.state.ticket.service.id)
      })
      .then(response => {
        if (response.data) {
          if (response.data.mensaje && response.data.mensaje != "") {
            this.mensaje_documento = response.data.mensaje;
            this.requiere_documento = true;
          }
        }
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "accent",
          position: "top",
          timeout: 5000
        });
      });
  }

  private getFormulario() {
    this.$axios
      .post("formularios/listar/front", {
        id_servicio: Number(this.$store.state.ticket.service.id)
      })
      .then(response => {
        if (response.data.length > 0) {
          this.data_preguntas = [];
          if (response.data && response.data.length > 0) {
            this.data_preguntas = response.data;
          }
        }
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "accent",
          position: "top",
          timeout: 5000
        });
      });
  }

  private getServicioMeta() {
    this.$axios
      .post("servicio_metas/detalle", {
        id_servicio: Number(this.$store.state.ticket.service.id)
      })
      .then(response => {
        this.mensaje = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "accent",
          position: "top",
          timeout: 5000
        });
      });
  }

  private sendTicketAttemp() {
    this.disable_button = true;
    if (!this.areAllFilesOK()) {
      this.$q.notify({
        message: this.$t("notification.problem_with_files") as string,
        color: "accent",
        position: "top",
        timeout: 7500
      });
      return (this.disable_button = false);
    }

    let captcha = (window as any).grecaptcha.getResponse();
    if (captcha == "") {
      this.$q.notify({
        message: this.$t("notification.check_captcha") as string,
        color: "accent",
        position: "top",
        timeout: 7500
      });
      return (this.disable_button = false);
    }

    //ordenar input de archivos filepond)
    let adjuntos_input = document.getElementsByName("Files");
    //backend no soporta vacio, debe ser null
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") {
        adjuntos_empty = false;
      }
    }
    if (adjuntos_empty) {
      adjuntos = null;
    }

    var flag = true;
    if (this.data_preguntas && this.data_preguntas.length > 0) {
      for (let i = 0; i < this.data_preguntas.length; i++) {
        if (
          this.data_preguntas[i].obligatorio == 1 &&
          this.data_preguntas[i].respuesta === ""
        ) {
          flag = false;
        }
      }
    }
    if (flag) {
      this.$axios
        .post(
          "tickets/solicitud",
          {
            origen: 4,
            id_servicio: this.$store.state.ticket.service.id,
            tipo: Number(this.$store.state.ticket.motive), //motivo de solicitud
            rut: this.$store.state.logged
              ? this.$store.state.user.rut
              : this.$store.state.ticket.guest_id,
            tipo_documento: this.$store.state.logged
              ? this.$store.state.user.document_type
              : this.$store.state.ticket.guest_document_type,
            adjunto: adjuntos,
            mensaje: this.formData.message,
            direccion: {
              calle: this.formData.street,
              numero: this.formData.street_number,
              aclaratoria: this.formData.hint
            },
            dispositivo: navigator.userAgent,
            id_formulario: 1,
            respuestas: this.data_preguntas
          },
          {
            headers: {
              Authorization: this.$store.state.user.auth_token
                ? this.$store.state.user.auth_token
                : null,
              Captcha: captcha
            }
          }
        )
        .then(response => {
          let ticket = {
            id: response.data.response.data.id_ticket,
            message: response.data.response.data.mensaje,
            date: response.data.response.data.fecha,
            step: 4,
            adjuntos: response.data.response.data.adjunto
              ? response.data.response.data.adjunto
              : null
          };
          this.$store.dispatch("submitRequestFinished", ticket);
        })
        .catch(error => {
          let message = "Ha ocurrido un error al generar su ticket";
          switch (error.response.data.error.message) {
            case "persona_no_data":
              message =
                "Ha ocurrido un error. Sus datos como vecino están incompletos";
              break;
            case "save_ticket_error":
              message = "Ha ocurrido un error al crear su solicitud";
              break;
            case "empty_captcha":
              message = "Ha ocurrido un error validando su captcha";
              break;
            case "invalid_captcha":
              message = "Ha ocurrido un error validando su captcha";
              break;
            case "empty_calle":
              message = "Necesita rellenar el campo calle";
              break;
            case "empty_numero":
              message = "Ha ocurrido un error validando su captcha";
              break;
          }
          this.$q.notify({
            message: message,
            color: "accent",
            position: "top",
            timeout: 5000
          });
        })
        .finally(() => {
          this.disable_button = false;
        });
    } else {
      this.disable_button = false;
      this.$q.notify({
        message: "Rellene los campos obligatorios",
        color: "accent",
        position: "top",
        timeout: 5000
      });
    }
  }

  private get canAutoFillAdress() {
    if (this.$store.state.logged) {
      if (this.$store.state.user.adress.district == this.comuna_cliente) {
        return true;
      }
    } else {
      if (
        this.$store.state.ticket.guest_adress_district == this.comuna_cliente
      ) {
        return true;
      }
    }
    return false;
  }

  private autoFillAdress() {
    if (this.$store.state.logged) {
      this.formData.street = this.$store.state.user.adress.street;
      this.formData.street_number = this.$store.state.user.adress.number;
      this.formData.hint = this.$store.state.user.adress.hint;
    } else {
      this.formData.street = this.$store.state.ticket.guest_adress_street;
      this.formData.street_number = this.$store.state.ticket.guest_adress_number;
      this.formData.hint = this.$store.state.ticket.guest_adress_hint;
    }
    return false;
  }

  private async filterStreet(val: string, update: any, abort: any) {
    this.street_loading = true;
    if (val === "") {
      update(() => {
        this.options_street = [];
      });
      this.street_loading = false;
      this.street_search_empty = true;
      return;
    }
    await this.loadStreets(val);

    setTimeout(() => {
      update(() => {});
    }, 200);

    return;
  }

  private async loadStreets(val: string) {
    const value = val.toLowerCase();
    let streets = await this.apiStreets(val);
    if (streets.err) {
      this.options_street = [];
      return;
    }
    this.street_loading = false;
    this.street_search_empty = false;
    this.options_street = streets;
  }

  private abortFilterFn() {}

  private filepondServer = {
    url: process.env.VUE_APP_API_PATH + "/temporal_archivos/",
    process: {
      url: "upload",
      method: "PUT",
      withCredentials: false,
      onload: (response: string) => {
        let json_response = JSON.parse(response);
        return json_response.response.nombre;
      },
      onerror: (response: any) => response.data,
      ondata: (formData: any) => {
        return formData;
      }
    },
    revert: "delete",
    restore: null,
    load: null,
    fetch: null
  };
}
